<template>

  <div class=" content_container">
    <div class='box'>
      <div class="top">
        <p v-if='num==1'>评价订单</p>
        <p v-else>评价详情</p>
        <dl>
          <dd>{{ order.out_request_no }}</dd>
          <dd>{{ order.date_added }}</dd>
        </dl>
      </div>
      <div class="cont">
        <el-row
          v-for="(goods, index) in order.get_order_product"
          :key="index"
        >
          <el-col :span="8">
            <div class="item">
              <div class="item-img-warper">
                <img
                  class="item-img"
                  :src="$config.baseUrl + goods.files_path"
                  @click="$util.toDetail(order.getshop.shop_type,  goods.product_id )"
                />
              </div>
              <div class="item-info">
                <div
                  class="item-name"
                  @click="$util.toDetail(order.getshop.shop_type,  goods.product_id )"
                >{{ goods.name }}</div>
                <div class="item-price">
                  <span class="price">
                    ￥{{ parseFloat(goods.price / 100).toFixed(2) }}
                  </span>
                  x
                  <span class="quantity">{{ goods.quantity }}</span>
                </div>

                <div> {{ order.shop_name }}</div>
              </div>
            </div>

          </el-col>

          <el-col :span="16">

            <el-row style="margin:0 auto">
              <el-col :span="6">商品质量</el-col>
              <el-col
                :span="18"
                style="height:50px"
              >
                <el-rate
                  v-if='num==1'
                  :disabled='false'
                  v-model="goods.comment_rank"
                  show-text
                  :texts="['非常差', '差', '一般', '好', '非常好']"
                >
                </el-rate>
                <el-rate
                  v-else
                  :disabled='true'
                  v-model="goods.getcommentg[0].comment_rank"
                  show-text
                  :texts="['非常差', '差', '一般', '好', '非常好']"
                >
                </el-rate>

              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">服务质量</el-col>
              <el-col
                :span="18"
                style="height:50px"
              >
                <el-rate
                  v-if='num==1'
                  :disabled='false'
                  v-model="goods.service_comment"
                  show-text
                  :texts="['非常差', '差', '一般', '好', '非常好']"
                >
                </el-rate>
                <el-rate
                  v-else
                  :disabled='true'
                  v-model="goods.getcommentg[0].service_comment"
                  show-text
                  :texts="['非常差', '差', '一般', '好', '非常好']"
                >
                </el-rate>

              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">物流质量</el-col>
              <el-col
                :span="18"
                style="height:50px"
              >

                <el-rate
                  v-if='num==1'
                  :disabled='false'
                  v-model="goods.shipping_comment"
                  show-text
                  :texts="['非常差', '差', '一般', '好', '非常好']"
                >
                </el-rate>
                <el-rate
                  v-else
                  :disabled='true'
                  v-model="goods.getcommentg[0].shipping_comment"
                  show-text
                  :texts="['非常差', '差', '一般', '好', '非常好']"
                >
                </el-rate>

              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">评价内容</el-col>
              <el-col :span="18">
                <p v-if='num==0'> {{goods.getcommentg[0].content}}</p>
                <el-input
                  v-else
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="goods.content"
                >

                </el-input>
                <dl>
                  <dd>
                    <p
                      class="tu"
                      v-if='num==0'
                    >

                      <img
                        v-for="(img, i) in (goods.getcommentg[0].gallery.split(','))"
                        :key="i"
                        :src='img'
                        @click="handlePictureCardPreview2"
                      >
                      <el-dialog
                        :visible.sync="dialogVisible"
                        style="width:100%"
                      >
                        <img
                          style="width:100%;height:500px "
                          :src="dialogImageUrl"
                          alt=""
                        >
                      </el-dialog>
                    </p>

                    <uploader
                      v-else
                      field="img"
                      value-type="urlStr"
                      v-model="gallery"
                      exts="png||jpg||gif"
                    />
                    <el-dialog :visible.sync="dialogVisible2">

                    </el-dialog>

                    <!-- <img  v-if-else = 'num!=0||' :src='$config.baseUrl+'> -->
                  </dd>

                </dl>

              </el-col>
            </el-row>
            <el-row :gutter="10" v-if='num==0'>
              <el-col :span="6"><p>商家回复</p></el-col>
              <el-col :span="18">
                <p> {{goods.getcommentg[0].reply}}</p>

              </el-col>
            </el-row>
            <el-row  :gutter="10"  v-if=' (JSON.parse(goods.getcommentg[0].reply_pic).length)' style="margin-top:10px">
              <el-col :span="6">附加图</el-col>
              <el-col :span="18">
                <img
                  v-for="(item, index) in JSON.parse(goods.getcommentg[0].reply_pic)"
                  :key="index"
                  :src="$config.baseUrl+item"
                  alt=""
                  style="width: 100px; height: 100px; margin-right: 10px"
                  @click="handlePictureCardPreview0"
                >
                <el-dialog
                  :visible.sync="dialogVisible0"
                  style="width:100%"
                >
                  <img
                    style="width:100%;height:500px "
                    :src="dialogImageUrl0"
                    alt=""
                  >
                </el-dialog>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>

      <div class="btn">

        <el-button
          v-if='num==1'
          type="primary"
          @click="toSend()"
        >
          发表
        </el-button>

        <el-button
          style="margin-left:20px; "
          @click="$router.go(-1)"
        >返回</el-button>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible2: false,
      dialogImageUrl: "",
         dialogImageUrl0: "",
      dialogVisible0: false,

      order: {},
      getrefund: "",
      comment_rank: 5,
      service_comment: 5,
      shipping_comment: 5,
      content: "",
      gallery: [],
      gallery2: [],
      dialogVisible: false,
      num: 1
    };
  },

  watch: {},
  computed: {},
  mounted() {
    this.getDetail();
    this.num = this.$route.query.num;
  },
  methods: {
    handlePictureCardPreview2(file) {
      this.dialogImageUrl = file.target.src;
      this.dialogVisible = true;
    },
    handlePictureCardPreview0(file) {
      this.dialogImageUrl0 = file.target.src;
      this.dialogVisible0 = true;
    },
    //发布评论
    toSend() {
      this.order.get_order_product.forEach(item => {
        let data = {
          comment_type: 0,
          id_value: item.product_id,
          shop_id: item.shop_id,
          order_id: item.order_id,
          user_logo: this.$store.state.member.avatar,
          user_name: this.$store.state.member.name,
          content: item.content,
          comment_rank: item.comment_rank,
          service_comment: item.service_comment,
          shipping_comment: item.shipping_comment,
          goodid: item.order_product_id,
          user_id: this.$store.state.member.id,
          gallery: this.gallery,
          shopname: this.order.shop_name,
          goodname: item.name,
          ordersn: item.order_id
        };
        this.$post("home/comment", data).then(res => {
          console.log(res);
          this.$message.success("已经发布评论");
          this.$router.push("/member-order");
        });
      });
    },

    //获取订单详情信息
    getDetail() {
      this.$get("home/order/" + this.$route.query.id).then(res => {
        console.log("home/order", res);
        let data = res;
        if (res.getcomment) {
          this.gallery2 = res.getcomment.gallery.split(",");
        }

        data.get_order_product.forEach(i => {
          this.$set(i, "content", "");
          this.$set(i, "comment_rank", 5);
          this.$set(i, "service_comment", 5);
          this.$set(i, "shipping_comment", 5);
        });
        console.log("  data.get_order_product", data.get_order_product);
        this.order = data;
        console.log("   this.order ", this.order.get_order_product);
      });
    }
  },
  components: {},
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.tu {
  img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
}
.box {
  background: #fff;
  padding: 40px;
  margin-top: 40px;
}
.btn {
  text-align: center;
  .el-button {
    width: 200px;
  }
}
.content_container {
  box-sizing: border-box;
}
.item-img-warper {
  margin-bottom: 20px;
  text-align: center;
  img {
    width: 160px;
    height: 160px;
  }
}
.item-info {
  .item-name {
    font-weight: bold;
  }
  div {
    text-align: center;
    margin-bottom: 10px;
  }
}
.top {
  p {
    line-height: 45px;

    font-size: 16px;
    font-weight: bold;
  }
  text-align: center;
  dl {
    dd {
      display: inline-block;
      padding: 10px 30px;
    }
    text-align: center;
  }
}
.cont {
  padding: 20px;
  dl {
    padding: 20px 0;
  }
}
.cont {
  width: 80%;
  margin: 0 auto;
}
</style>
